<template>
    <section class="our-studio" v-if="data">

        <div class="day-in-the-life" v-scroll-reveal="{ distance: '0px', beforeReveal: revealTop }">

            <div class="day-in-the-life__copy">
                <img src="/img/global/sms-logo--mark.svg" alt="Santa Monica Studios logo mark" class="day-in-the-life__copy__logo--mark">
                <div class="day-in-the-life__copy__subheading">{{ data.dayInTheLife.subheading }}</div>
                <h2 class="day-in-the-life__copy__header" v-html="data.dayInTheLife.header" ></h2>
                <div class="day-in-the-life__right-image-mobile" v-scroll-reveal="{ distance: '0px', beforeReveal: revealMobileTop }">

                    <svg role = "img" aria-label = "A decorative frame border." class="ourStudioTop" enable-background="new 0 0 731.89 479.7" viewBox="0 0 731.89 479.7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <clipPath id="a-m">
                            <path d="m709.56 24.03c0 51.54-9.04 100.97-25.61 146.79-60.06 166.02-219.1 284.67-405.85 284.67v-431.46z" />
                        </clipPath>
                        <clipPath id="b-m">
                            <path d="m133.34 102.91c-19.12 0-36.38 7.73-48.92 20.26-12.53 12.54-20.26 29.79-20.26 48.91v53.85 16.84h70.69c19.12 0 36.38-7.73 48.92-20.26 12.53-12.54 20.26-29.79 20.26-48.91v-53.85-16.84z" />
                        </clipPath>
                        <g clip-path="url(#a-m)">
                            <image class="image1" height="800" overflow="visible" transform="matrix(.5427 0 0 .5427 275.2786 22.326)" width="800" :xlink:href="data.dayInTheLife.image1.src"><desc>{{ data.dayInTheLife.image1.alt }}</desc></image>
                        </g>
                        <g clip-path="url(#b-m)" transform="translate(-.000004)">
                            <image height="800" class="image2" overflow="visible" transform="matrix(.1765 0 0 .1765 63.5029 102.3149)" width="800" :xlink:href="data.dayInTheLife.image2.src"><desc>{{ data.dayInTheLife.image2.alt }}</desc></image>
                        </g>

                        <!-- red -->
                        <g stroke="#b22028" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                            <path class="left-dot" d="m25.74 414.33v-16.16" fill="none" stroke-dasharray="0 32.3021" />
                            <g stroke-miterlimit="10">
                                <path class="left-left" d="m25.74 190.89v164.02" fill="#fff" />
                                <path class="left-middle" d="m125.67 102.91c-14.93 2.46-28.44 9.28-37.62 17.36-15.4 13.56-23.43 29.6-23.43 51.81v70.69" fill="none" />
                                <path class="left-left-top" d="m131.36 103.02h-37.43c-37.66 0-68.19 30.53-68.19 68.19v71.44" fill="none" />
                                <path class="left-bottom-horizontal" d="m25.74 242.65h39.01" fill="none" />
                                <path class="left-right" d="m64.16 242.77h70.69c19.12 0 36.38-7.73 48.92-20.26 12.53-12.54 20.26-29.79 20.26-48.91v-53.85-16.84" fill="none" />
                                <path class="left-middle-horizontal" d="m204.03 102.91h-70.69c-19.12 0-36.38 7.73-48.92 20.26-12.53 12.54-20.26 29.79-20.26 48.91v53.85 16.84" fill="none" />
                                <path class="left-top-horizontal" d="m278.1 102.91h-74.07" fill="none" />
                            </g>
                        </g>
                        <path class="studio-mobile-top-arc" d="m356.99 24.03h-78.88v78.88c43.56 0 78.88-35.32 78.88-78.88z" fill="#fff" />
                        <g stroke="#b22028" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">
                            <path class="pie-line" d="m278.1 102.91c43.57 0 78.88-35.32 78.88-78.88" fill="#fff" />
                            <path class="right-arc" d="m709.56 24.03c0 51.54-9.04 100.97-25.61 146.79-60.06 166.02-219.1 284.67-405.85 284.67" fill="none" />
                            <path class="right-vertical" d="m278.1 455.49v-431.46" fill="none" />
                            <path class="right-horizontal" d="m278.1 24.03h431.46" fill="none" />
                        </g>

                        <!-- tan -->
                        <g stroke="#d6d0b4" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                            <path class="left-dot" d="m25.74 414.33v-16.16" fill="none" stroke-dasharray="0 32.3021" />
                            <g stroke-miterlimit="10">
                                <path class="left-left" d="m25.74 190.89v164.02" fill="#fff" />
                                <path class="left-middle" d="m125.67 102.91c-14.93 2.46-28.44 9.28-37.62 17.36-15.4 13.56-23.43 29.6-23.43 51.81v70.69" fill="none" />
                                <path class="left-left-top" d="m131.36 103.02h-37.43c-37.66 0-68.19 30.53-68.19 68.19v71.44" fill="none" />
                                <path class="left-bottom-horizontal" d="m25.74 242.65h39.01" fill="none" />
                                <path class="left-right" d="m64.16 242.77h70.69c19.12 0 36.38-7.73 48.92-20.26 12.53-12.54 20.26-29.79 20.26-48.91v-53.85-16.84" fill="none" />
                                <path class="left-middle-horizontal" d="m204.03 102.91h-70.69c-19.12 0-36.38 7.73-48.92 20.26-12.53 12.54-20.26 29.79-20.26 48.91v53.85 16.84" fill="none" />
                                <path class="left-top-horizontal" d="m278.1 102.91h-74.07" fill="none" />
                            </g>
                        </g>
                        <path class="studio-mobile-top-arc" d="m356.99 24.03h-78.88v78.88c43.56 0 78.88-35.32 78.88-78.88z" fill="#fff" />
                        <g stroke="#d6d0b4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">
                            <path class="pie-line" d="m278.1 102.91c43.57 0 78.88-35.32 78.88-78.88" fill="#fff" />
                            <path class="right-arc" d="m709.56 24.03c0 51.54-9.04 100.97-25.61 146.79-60.06 166.02-219.1 284.67-405.85 284.67" fill="none" />
                            <path class="right-vertical" d="m278.1 455.49v-431.46" fill="none" />
                            <path class="right-horizontal" d="m278.1 24.03h431.46" fill="none" />
                        </g>

                        <!-- black -->
                        <g stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                            <path class="left-dot" d="m25.74 414.33v-16.16" fill="none" stroke-dasharray="0 32.3021" />
                            <g stroke-miterlimit="10">
                                <path class="left-left" d="m25.74 190.89v164.02" fill="#fff" />
                                <path class="left-middle" d="m125.67 102.91c-14.93 2.46-28.44 9.28-37.62 17.36-15.4 13.56-23.43 29.6-23.43 51.81v70.69" fill="none" />
                                <path class="left-left-top" d="m131.36 103.02h-37.43c-37.66 0-68.19 30.53-68.19 68.19v71.44" fill="none" />
                                <path class="left-bottom-horizontal" d="m25.74 242.65h39.01" fill="none" />
                                <path class="left-right" d="m64.16 242.77h70.69c19.12 0 36.38-7.73 48.92-20.26 12.53-12.54 20.26-29.79 20.26-48.91v-53.85-16.84" fill="none" />
                                <path class="left-middle-horizontal" d="m204.03 102.91h-70.69c-19.12 0-36.38 7.73-48.92 20.26-12.53 12.54-20.26 29.79-20.26 48.91v53.85 16.84" fill="none" />
                                <path class="left-top-horizontal" d="m278.1 102.91h-74.07" fill="none" />
                            </g>
                        </g>
                        <path class="studio-mobile-top-arc" d="m356.99 24.03h-78.88v78.88c43.56 0 78.88-35.32 78.88-78.88z" fill="#fff" />
                        <g stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">
                            <path class="pie-line" d="m278.1 102.91c43.57 0 78.88-35.32 78.88-78.88" fill="#fff" />
                            <path class="right-arc" d="m709.56 24.03c0 51.54-9.04 100.97-25.61 146.79-60.06 166.02-219.1 284.67-405.85 284.67" fill="none" />
                            <path class="right-vertical" d="m278.1 455.49v-431.46" fill="none" />
                            <path class="right-horizontal" d="m278.1 24.03h431.46" fill="none" />
                        </g>

                    </svg>

                </div>
                <p class="day-in-the-life__copy__description" v-html="data.dayInTheLife.description"></p>
            </div>
            <div class="day-in-the-life__right-image">

                <svg role = "img" aria-label = "A decorative frame border." class="ourStudioTop" enable-background="new 0 0 620 981" viewBox="0 0 620 981" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <clipPath id="a">
                        <path d="m538.04 12.88c-62.15 0-121.76 10.9-177.01 30.89-200.2 72.43-343.26 264.2-343.26 489.39h520.28v-520.28z" />
                    </clipPath>
                    <clipPath id="b">
                        <path d="m537.75 715.53v118.68c0 32.1-12.98 61.08-34.02 82.12-21.05 21.03-50.02 34.01-82.12 34.02h-117.75v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02z" />
                    </clipPath>
                    <g clip-path="url(#a)">
                        <image class="image1" height="800" overflow="visible" transform="matrix(.65 0 0 .65 18 13)" width="800" :xlink:href="data.dayInTheLife.image1.src"><desc>{{ data.dayInTheLife.image1.alt }}</desc></image>
                    </g>
                    <g clip-path="url(#b)">
                        <image class="image2" height="800" overflow="visible" transform="matrix(.2925 0 0 .2925 304 714)" width="800" :xlink:href="data.dayInTheLife.image2.src"><desc>{{ data.dayInTheLife.image2.alt }}</desc></image>
                    </g>
                    <path class="pie-fill" d="m538.04 438.04v95.12h-95.12c0-52.53 42.59-95.12 95.12-95.12z" fill="#fff" />

                    <g stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">

                        <!-- red -->
                        <path class="bottom-bottom" d="m603.75 713.93v118.68c0 32.1-12.98 61.08-34.02 82.12-21.05 21.03-50.02 34.01-82.12 34.02h-183.75" fill="none" stroke="#b22028" />
                        <path class="bottom-left" d="m303.86 948.75v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02h118" fill="none" stroke="#b22028" />
                        <!-- <path d="m603.75 713.93v118.68c0 64.14-52 116.14-116.14 116.14h-65.8" fill="none" /> -->
                        <path class="bottom-horizontal" d="m603.75 713.93h-65.58" fill="#fff" stroke="#b22028" />
                        <path class="middle-line" d="m421.82 948.74s109.36 2.54 116.35-116.14v-118.67-181.48" fill="none" stroke="#b22028" />
                        <path class="top-arc-lg" d="m538.04 12.88c-62.15 0-121.76 10.9-177.01 30.89-200.2 72.43-343.26 264.2-343.26 489.39" fill="none" stroke="#b22028" />
                        <path class="top-arc-sm" d="m442.92 533.16c0-52.53 42.59-95.12 95.12-95.12" fill="none" stroke="#b22028" />
                        <path class="top-horizontal" d="m17.77 533.16h520.27" fill="none" stroke="#b22028" />
                        <path class="top-vertical" d="m538.04 533.16v-520.28" fill="none" stroke="#b22028" />

                        <!-- tan -->
                        <path class="bottom-bottom" d="m603.75 713.93v118.68c0 32.1-12.98 61.08-34.02 82.12-21.05 21.03-50.02 34.01-82.12 34.02h-183.75"
                            fill="none" stroke="#d6d0b4" />
                        <path class="bottom-left" d="m303.86 948.75v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02h118"
                            fill="none" stroke="#d6d0b4" />
                        <!-- <path d="m603.75 713.93v118.68c0 64.14-52 116.14-116.14 116.14h-65.8" fill="none" /> -->
                        <path class="bottom-horizontal" d="m603.75 713.93h-65.58" fill="#fff" stroke="#d6d0b4" />
                        <path class="middle-line" d="m421.82 948.74s109.36 2.54 116.35-116.14v-118.67-181.48" fill="none" stroke="#d6d0b4" />
                        <path class="top-arc-lg" d="m538.04 12.88c-62.15 0-121.76 10.9-177.01 30.89-200.2 72.43-343.26 264.2-343.26 489.39" fill="none" stroke="#d6d0b4" />
                        <path class="top-arc-sm" d="m442.92 533.16c0-52.53 42.59-95.12 95.12-95.12" fill="none" stroke="#d6d0b4" />
                        <path class="top-horizontal" d="m17.77 533.16h520.27" fill="none" stroke="#d6d0b4" />
                        <path class="top-vertical" d="m538.04 533.16v-520.28" fill="none" stroke="#d6d0b4" />

                        <!-- black -->
                        <path class="bottom-bottom" d="m603.75 713.93v118.68c0 32.1-12.98 61.08-34.02 82.12-21.05 21.03-50.02 34.01-82.12 34.02h-183.75"
                            fill="none" stroke="#1c1b19" />
                        <path class="bottom-left" d="m303.86 948.75v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02h118"
                            fill="none" stroke="#1c1b19" />
                        <!-- <path d="m603.75 713.93v118.68c0 64.14-52 116.14-116.14 116.14h-65.8" fill="none" /> -->
                        <path class="bottom-horizontal" d="m603.75 713.93h-65.58" fill="#fff" stroke="#1c1b19" />
                        <path class="middle-line" d="m421.82 948.74s109.36 2.54 116.35-116.14v-118.67-181.48" fill="none" stroke="#1c1b19" />
                        <path class="top-arc-lg" d="m538.04 12.88c-62.15 0-121.76 10.9-177.01 30.89-200.2 72.43-343.26 264.2-343.26 489.39" fill="none" stroke="#1c1b19" />
                        <path class="top-arc-sm" d="m442.92 533.16c0-52.53 42.59-95.12 95.12-95.12" fill="none" stroke="#1c1b19" />
                        <path class="top-horizontal" d="m17.77 533.16h520.27" fill="none" stroke="#1c1b19" />
                        <path class="top-vertical" d="m538.04 533.16v-520.28" fill="none" stroke="#1c1b19" />

                    </g>

                </svg>

            </div>


        </div>

        <image-gallery class="story__gallery" :slides="data.slides" />

        <waistband-image :waistbandImageSrc='data.waistbandImage.src' :waistbandImageAltTxt='data.waistbandImage.alt' :waistbandCaption='data.waistbandImage.captionMaxChar'
        :waistbandSource='data.waistbandImage.sourceName'
        :waistbandRole='data.waistbandImage.sourceTitle'
        v-scroll-reveal/>


        <div class="twenty-years" v-scroll-reveal="{ distance: '0px', beforeReveal: revealTwentyYears }">
            <div class="twenty-years__left-images">

            <svg role = "img" aria-label = "A decorative frame border." version="1.1" class="main-image" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 758.82 762.53" style="enable-background:new 0 0 758.82 762.53;" xml:space="preserve">
                <g>
                    <defs>
                        <path id="SVGID_1_" d="M736.44,739.23H614.53c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36V498.01 h121.92c32.98,0,62.74,13.34,84.36,34.94c21.61,21.62,34.94,51.39,34.94,84.36V739.23z"/>
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                    </clipPath>
                    <g transform="matrix(1 0 0 1 -3.051758e-05 0)" style="clip-path:url(#SVGID_2_);">
                        <image style="overflow:visible;" width="800" height="800" class="HrAq5S" id="HrAq5S" :xlink:href="data.twentyYears.img2.src"  transform="matrix(0.2999 0 0 0.2999 494.9627 499.3105)"><desc>{{ data.twentyYears.img2.alt }}</desc></image>
                    </g>
                </g>
                <g>
                    <defs>
                        <path id="SVGID_3_" d="M495.22,498.01H254.18c-65.2-0.01-124.05-26.37-166.8-69.09c-42.72-42.75-69.08-101.6-69.09-166.8V21.07 h57.41h183.64c65.2,0.01,124.05,26.37,166.8,69.09c42.72,42.75,69.08,101.6,69.09,166.8V498.01z"/>
                    </defs>
                    <clipPath id="SVGID_4_">
                        <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
                    </clipPath>
                    <g style="clip-path:url(#SVGID_4_);">
                        <image style="overflow:visible;" width="800" height="800" class="pvwaGg" id="pvwaGg" :xlink:href="data.twentyYears.img1.src"   transform="matrix(0.5962 0 0 0.5962 18.2831 21.0732)"><desc>{{ data.twentyYears.img1.alt }}</desc></image>
                    </g>
                </g>
                <rect class="Square-White-Fill" id="Square-White-Fill" x="374.61" y="377.4" style="fill:#FFFFFF;" width="120.61" height="120.61"/>

                <!-- red -->
                <path class="small-arc" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M493.92,618.62c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36"/>
                <line class="square-top" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="374.61" y1="377.4" x2="494.96" y2="377.4"/>
                <line class="square-left" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="374.61" y1="497.31" x2="374.61" y2="377.4"/>
                <path class="twenty-bottom-left" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M736.44,739.23H614.53c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36V498.01"/>
                <path class="bottom-right" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M495.22,498.01h121.92c32.98,0,62.74,13.34,84.36,34.94c21.61,21.62,34.94,51.39,34.94,84.36v121.91"/>
                <path class="top-right" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M18.28,21.07h57.41h183.64c65.2,0.01,124.05,26.37,166.8,69.09c42.72,42.75,69.08,101.6,69.09,166.8v241.05"/>
                <path class="top-left" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M495.22,498.01H254.18c-65.2-0.01-124.05-26.37-166.8-69.09c-42.72-42.75-69.08-101.6-69.09-166.8V21.07"/>

                <!-- tan -->
                <path class="small-arc" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M493.92,618.62c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36"/>
                <line class="square-top" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="374.61" y1="377.4" x2="494.96" y2="377.4"/>
                <line class="square-left" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="374.61" y1="497.31" x2="374.61" y2="377.4"/>
                <path class="twenty-bottom-left" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M736.44,739.23H614.53c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36V498.01"/>
                <path class="bottom-right" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M495.22,498.01h121.92c32.98,0,62.74,13.34,84.36,34.94c21.61,21.62,34.94,51.39,34.94,84.36v121.91"/>
                <path class="top-right" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M18.28,21.07h57.41h183.64c65.2,0.01,124.05,26.37,166.8,69.09c42.72,42.75,69.08,101.6,69.09,166.8v241.05"/>
                <path class="top-left" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M495.22,498.01H254.18c-65.2-0.01-124.05-26.37-166.8-69.09c-42.72-42.75-69.08-101.6-69.09-166.8V21.07"/>

                <!-- black -->
                <path class="small-arc" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M493.92,618.62c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36"/>
                <line class="square-top" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="374.61" y1="377.4" x2="494.96" y2="377.4"/>
                <line class="square-left" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="374.61" y1="497.31" x2="374.61" y2="377.4"/>
                <path class="twenty-bottom-left" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M736.44,739.23H614.53c-32.98,0-62.74-13.34-84.36-34.94c-21.61-21.62-34.94-51.39-34.94-84.36V498.01"/>
                <path class="bottom-right" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M495.22,498.01h121.92c32.98,0,62.74,13.34,84.36,34.94c21.61,21.62,34.94,51.39,34.94,84.36v121.91"/>
                <path class="top-right" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M18.28,21.07h57.41h183.64c65.2,0.01,124.05,26.37,166.8,69.09c42.72,42.75,69.08,101.6,69.09,166.8v241.05"/>
                <path class="top-left" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M495.22,498.01H254.18c-65.2-0.01-124.05-26.37-166.8-69.09c-42.72-42.75-69.08-101.6-69.09-166.8V21.07"/>

                </svg>

                <img src="/img/ourStudio/20-Years-Our-Studio.svg" alt="Image reads '20 years of greatness.'" class="accent-image">
            </div>
            <div class="twenty-years__description" v-html="data.twentyYears.description"></div>
        </div>

        <div class="the-team" >
            <div class="the-team__subheading" v-html="data.theTeam.subheading" v-scroll-reveal="{ distance: '50%', origin: 'left', delay: 0}"></div>
            <h2 class="the-team__header" v-html="data.theTeam.header" v-scroll-reveal="{ distance: '50%', distance: '0', opacity: 0, delay: 500}"></h2>
            <div class="the-team__text-and-image" v-scroll-reveal="{ distance: '0px', beforeReveal: revealTheTeam }">
                <div class="the-team__text-and-image__description" v-html="data.theTeam.description"></div>
                <div class="the-team__text-and-image__apply-images">

                    <svg role = "img" aria-label = "A decorative frame border." version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 752.87 747.17" style="enable-background:new 0 0 752.87 747.17;" xml:space="preserve">
                        <g>
                            <defs>
                                <path id="team-SVGID_1_" d="M230.35,226.12h501.2c0,501.2-501.2,494.69-501.2,494.69V226.12" />
                            </defs>
                            <clipPath id="team-SVGID_2_">
                                <use xlink:href="#team-SVGID_1_" style="overflow:visible;" />
                            </clipPath>
                            <g transform="matrix(1 0 0 1 0 0)" style="clip-path:url(#team-SVGID_2_);">
                                <image class="team-image" style="overflow:visible;" width="800" height="800" id="S7yFG3" :xlink:href="data.theTeam.img1.src" transform="matrix(0.6277 0 0 0.6277 230.7692 226.4335)"><desc>{{ data.theTeam.img1.alt }}</desc></image>
                            </g>
                        </g>
                        <path id="team-Shape_Fill" class="team-Shape_Fill" style="fill:#DFD9C8;" d="M229.85,226.09c0,0-209.72-2.72-209.72,207h209.72" />

                        <!-- red -->
                        <line class="team-Top-Right-Pie-Vertical" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="440.08" y1="19.43" x2="440.08" y2="226.43" />
                        <path class="team-Top-Right-Pie-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M230.35,226.43c0-209.72,209.72-207,209.72-207" />
                        <line class="team-Left-Pie-Horiz" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="20.12" y1="433.09" x2="229.85" y2="433.09" />
                        <path class="team-Left-Pie-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M229.85,226.09c0,0-209.72-2.72-209.72,207" />
                        <path class="team-Big-Pie-Curve" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M731.56,226.12c0,501.2-501.2,494.69-501.2,494.69" />
                        <line class="team-Big-Pie-Vertical" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="230.35" y1="720.81" x2="230.35" y2="226.12" />
                        <line class="team-Big-Pie-Horiz" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="230.35" y1="226.12" x2="731.56" y2="226.12" />

                        <!-- tan -->
                        <line class="team-Top-Right-Pie-Vertical" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="440.08" y1="19.43" x2="440.08" y2="226.43" />
                        <path class="team-Top-Right-Pie-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M230.35,226.43c0-209.72,209.72-207,209.72-207" />
                        <line class="team-Left-Pie-Horiz" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="20.12" y1="433.09" x2="229.85" y2="433.09" />
                        <path class="team-Left-Pie-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M229.85,226.09c0,0-209.72-2.72-209.72,207" />
                        <path class="team-Big-Pie-Curve" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M731.56,226.12c0,501.2-501.2,494.69-501.2,494.69" />
                        <line class="team-Big-Pie-Vertical" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="230.35" y1="720.81" x2="230.35" y2="226.12" />
                        <line class="team-Big-Pie-Horiz" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="230.35" y1="226.12" x2="731.56" y2="226.12" />

                        <!-- black -->
                        <line class="team-Top-Right-Pie-Vertical" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="440.08" y1="19.43" x2="440.08" y2="226.43" />
                        <path class="team-Top-Right-Pie-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M230.35,226.43c0-209.72,209.72-207,209.72-207" />
                        <line class="team-Left-Pie-Horiz" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="20.12" y1="433.09" x2="229.85" y2="433.09" />
                        <path class="team-Left-Pie-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M229.85,226.09c0,0-209.72-2.72-209.72,207" />
                        <path class="team-Big-Pie-Curve" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M731.56,226.12c0,501.2-501.2,494.69-501.2,494.69" />
                        <line class="team-Big-Pie-Vertical" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="230.35" y1="720.81" x2="230.35" y2="226.12" />
                        <line class="team-Big-Pie-Horiz" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="230.35" y1="226.12" x2="731.56" y2="226.12" />

                    </svg>
                </div>
            </div>
        </div>


        <perks-slider v-scroll-reveal :passedInData="data" />

        <waistband-image :waistbandImageSrc='data.waistbandImage2.src' :waistbandImageAltTxt='data.waistbandImage2.alt' :waistbandCaption='data.waistbandImage2.captionMaxChar'
        :waistbandSource='data.waistbandImage2.sourceName'
        :waistbandRole='data.waistbandImage2.sourceTitle'
        v-scroll-reveal/>

        <site-footer></site-footer>
    </section>
</template>

<script>
    import Vue from 'vue'
    import OurStudioMixin from '../mixins/OurStudioTimeline.js'
    import Meta from '../mixins/Meta.js'
    import 'jquery-ui/ui/widgets/slider.js';
    import WaistbandImage from '@/components/WaistbandImage.vue'
    import ImageGallery from '../components/ImageGallery.vue'
    import Footer from '@/components/Footer'
    import PerksSlider from '@/components/PerksSlider.vue'
    import SlickCarousels from '@/helpers/SlickCarousels.js'
    import axios from 'axios'

    import gsap, {Power2, Power3} from 'gsap'
    import SplitText from "gsap/SplitText"
    gsap.registerPlugin(SplitText)

    export default {
        mixins: [OurStudioMixin, Meta],
        components: {
            WaistbandImage,
            ImageGallery,
            'site-footer' : Footer,
            PerksSlider
        },
        data() {
            return {
                data: null
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                let slug = this.$route.params.jobId;
                axios.get(this.$json('/data/studioLife/studio-life.json'))

                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);
                        this.$sectionLoaded();
                        Vue.nextTick(() => {
                            this.setModalDataIfShowingModal();

                            this.initTimeline();
                            this.initSlider();
                            setTimeout(() => {
                                // in mixin
                                this.tl.play();
                            }, 600);
                        });
                        this.$checkAgeGate(this.data.ageGate);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            setModalDataIfShowingModal() {

                // if deeplinking to media modal, need to trigger click
                // event so current mediaModal assets are set properly
                if (this.$route.params.mediaId) {
                    $('a[href="' + this.$route.path + '"]').get(0).click();
                }

            },

            revealTop() {
                var topHeadline = gsap.timeline({delay:1})

                topHeadline.from($('.day-in-the-life__copy__logo--mark'), .25, { x:-50, opacity: 0, ease: Power2.easeInOut }, 0);

                topHeadline.from($('.day-in-the-life__copy__subheading'), .5, { x:-50, letterSpacing: ".7em", opacity: 0, ease: Power2.easeInOut }, 0);

                topHeadline.from($('.day-in-the-life__copy__description'), 1, {opacity:0, x:-50, ease: Power2.easeInOut }, 0);

                var mySplitText = new SplitText(".day-in-the-life__copy__header", {
                    type: "lines",
                    linesClass: 'line-split'
                });

                $('.line-split').each(function(i, el) {
                    var curLine = $(el).wrap('<div class="line-split-wrap"></div>');
                })

                var lines = mySplitText.lines; //an array of all the divs that wrap each character
                topHeadline.from(lines, .5, {
                    yPercent: 100,
                    ease: Power3.easeInOut,
                    stagger:0.03
                }, "-=.75");
            },
            revealTwentyYears() {
                var twentyYearsTl = gsap.timeline({delay:1})
                twentyYearsTl.timeScale(2.7);
                let stagger = 0.3;

                twentyYearsTl.fromTo($('.top-left'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                twentyYearsTl.fromTo($('.top-right'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

                twentyYearsTl.fromTo($('.twenty-bottom-left'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                twentyYearsTl.fromTo($('.bottom-right'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

                twentyYearsTl.fromTo($('.small-arc'), 1, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);
                twentyYearsTl.fromTo($('.square-top'), 1, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);
                twentyYearsTl.fromTo($('.square-left'), 1, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);

                twentyYearsTl.fromTo($('.HrAq5S'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=1.5");
                twentyYearsTl.fromTo($('.pvwaGg'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=1.5");
                // twentyYearsTl.fromTo($('.Square-White-Fill'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=1");

                twentyYearsTl.fromTo($('.twenty-years__description > p'), 1.5, { opacity: 0 , x:50}, { opacity: 1, x:0, ease: Power2.easeInOut }, "-=1");
            },
            revealTheTeam() {
                var theTeamTl = gsap.timeline({delay:1})
                theTeamTl.timeScale(2.7);
                // theTeamTl.timeScale(1);
                let stagger = 0.3;

                theTeamTl.fromTo($('.team-Big-Pie-Vertical'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                theTeamTl.fromTo($('.team-Big-Pie-Horiz'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

                theTeamTl.fromTo($('.team-Left-Pie-Curve'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                theTeamTl.fromTo($('.team-Top-Right-Pie-Curve'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

                theTeamTl.fromTo($('.team-Left-Pie-Horiz'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.5);
                theTeamTl.fromTo($('.team-Top-Right-Pie-Vertical'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.5);

                theTeamTl.fromTo($('.team-Big-Pie-Curve'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2.5);


                theTeamTl.fromTo($('.team-image'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, 3);
                theTeamTl.fromTo($('.team-Shape_Fill'), 1.5, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, 3);


                theTeamTl.fromTo($('.the-team__text-and-image__description > p'), 1.5, { opacity: 0 , x:-50}, { opacity: 1, x:0, ease: Power2.easeInOut }, "-=1");
            },
            revealMobileTop() {
                var mobileTopTl = gsap.timeline({delay:1})
                mobileTopTl.timeScale(2.7);
                let stagger = 0.3;

                mobileTopTl.fromTo($('.right-vertical'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                mobileTopTl.fromTo($('.right-horizontal'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
                mobileTopTl.fromTo($('.right-arc'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2.5);
                mobileTopTl.fromTo($('.studio-mobile-top-arc'), 3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 4);

                mobileTopTl.fromTo($('.pie-line'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);
                mobileTopTl.fromTo($('.left-top-horizontal'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2);

                mobileTopTl.fromTo($('.left-middle-horizontal'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 3.5);
                mobileTopTl.fromTo($('.left-right'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 3.5);

                mobileTopTl.fromTo($('.left-left-top'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 4.5);
                mobileTopTl.fromTo($('.left-middle'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 4.5);

                mobileTopTl.fromTo($('.left-left'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 5.5);
                mobileTopTl.fromTo($('.left-bottom-horizontal'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 5);

                mobileTopTl.fromTo($('.left-dot'), 2, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 6);
            }
        }
    }
</script>
