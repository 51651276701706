<template>
    <div class="gallery">
        <div class="gallery__images" >
            <button class = "slick-prev" tabindex="0" title = "Previous button for the image slider.">Previous slide button</button>
            <div class="gallery__images__carousel">
                <div class = "slide" v-for="slide in slides">
                    <img :src="slide.imagePath" :alt = "slide.subHeadline + ' icon'">
                </div>
            </div> 
            <button class = "slick-next" tabindex="0" title = "Next button for the image slider.">Next slide button</button>
        </div>
        <div class="gallery__caption" v-scroll-reveal="{ distance: '50%', origin: 'right', delay: 500}">
            <div class = "slides">
                <div class = "slide" v-for="slide in slides">
                    <div class="gallery__caption__title">
                        {{ slide.subHeadline }}
                    </div>
                    <div class="gallery__caption__subtitle" v-html="slide.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Slick from 'slick-carousel';
    import gsap from 'gsap'

    export default {
        props: ['slides'],
        mounted() {
            this.init();
        },
        methods: {
            init() {
                $('.gallery__images__carousel').each((i, el) => {
                    var $curCarousel = $(el);
                    $curCarousel
                        .on('init', (event, slick) => {
                            this.updateCounter(event, slick, 0);
                            this.showCaption($curCarousel, slick, 0);
                        })
                        .slick({
                            speed: 350,
                            mobileFirst: true,
                            prevArrow: $('.slick-next'),
                            nextArrow: $('.slick-prev'),
                            responsive: [{
                                breakpoint: 700,
                                settings: {
                                    speed: 500
                                }
                            }]
                        })
                        .on('beforeChange', (event, slick, currentSlide, nextSlide) => {
                            this.updateCounter(event, slick, nextSlide);
                            this.showCaption($curCarousel, slick, nextSlide);
                        })
                });
            },

            updateCounter(event, slick, nextSlide) {
                let $curField = $(event.currentTarget).parent().find('.gallery__images__counter__current');
                $curField.html(''); // setting to empty helps fix safari redraw glitch
                $curField.html((slick.currentSlide < 10 ? '0' : '') + (nextSlide + 1));
            },

            showCaption($caption, slick, nextSlide) {
                $caption.parent().parent().find('.gallery__caption').each((i, el) => {
                    var $curCaption = $(el).find('.slide');
                    gsap.killTweensOf($curCaption);
                    $curCaption.hide();
                    gsap.fromTo($($curCaption.get(nextSlide)), 0.6, {
                        display: 'none',
                        xPercent: 0,
                        opacity: 0
                    }, {
                        opacity: 1,
                        display: 'block',
                        xPercent: 0,
                        force3D: true
                    })
                });
            }
        }
    }
</script>